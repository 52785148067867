import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { APICommonService } from '@nationwide/core-component-library-base';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { commonConstants, CookieKeys, LANDING_PAGES, LOGGER_CONSTANTS } from '../../CONSTANTS/tpi-common-constants';
import { CompanyInfo } from '../../tpi-models/general/company-info';
import { SearchFields } from '../../tpi-models/general/search-forms';
import { TPISession } from '../../tpi-models/general/tpisession';
import { UserInfoResponse } from '../../tpi-models/login-logout/user-info';
import { ManageUserAccountsAccountInfo } from '../../tpi-models/manage-accounts-company-web/manage-user-accounts-account-info';
import { AuthorDetails, ModifyUserAccountInfo } from '../../tpi-models/manage-accounts-company-web/modify-user-account-info';
import { LoggerService } from '../logger/logger.service';
import { ManageUserAccountsService } from '../manage-accounts-company-web-services/manage-user-accounts.service';
@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private checkLoginSub = new BehaviorSubject(true);
    private hasAccountLocked = false;
    private hasChangedPassword = false;
    private hasConfirmedEmailAddress = false;
    private hasEnteredEmailAddress = false;
    private loggedIn = false;
    // eslint-disable-next-line max-params
    constructor(
        private apiCommonService: APICommonService,
        private http: HttpClient,
        private loggerService: LoggerService,
        private route: Router,
        private ngZone: NgZone,
        private logger: LoggerService,
        private manageUserAccountsService: ManageUserAccountsService,
        @Inject('policyRequestsConfig') private environment,
        @Inject('window') private window,
        @Inject('document') private document
    ) { }

    get changedPassword(): boolean {
        return this.hasChangedPassword;
    }
    set changedPassword(value: boolean) {
        this.hasChangedPassword = value;
    }

    get confirmedEmailAddress(): boolean {
        return this.hasConfirmedEmailAddress;
    }
    set confirmedEmailAddress(value: boolean) {
        this.hasConfirmedEmailAddress = value;
    }

    get enteredEmailAddress(): boolean {
        return this.hasEnteredEmailAddress;
    }
    set enteredEmailAddress(value: boolean) {
        this.hasEnteredEmailAddress = value;
    }

    get accountLocked(): boolean {
        return this.hasAccountLocked;
    }
    set accountLocked(value: boolean) {
        this.hasAccountLocked = value;
    }

    deleteAllCookies(): void {
        const cookies = this.document.cookie.split(';');
        for (const cookie of cookies) {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.slice(0, Math.max(0, eqPos)) : cookie;
            const domain = this.getCookieDomain();
            this.document.cookie = `${name}=;domain=${domain};${commonConstants.COOKIES.EXPIRES}`;
        }
    }

    getAuthorDetails(): AuthorDetails {
        const sessionDetails = this.getSession();
        const authorDetails: AuthorDetails = {
            userName: undefined,
            role: undefined,
            companyName: undefined,
            companyId: undefined,
            userId: undefined
        };
        if (sessionDetails?.context?.session?.account) {
            const account = sessionDetails.context.session.account;
            authorDetails.userName = account.shortName;
            authorDetails.role = account.role.id;
            authorDetails.userId = account.id;
            if (account?.company) {
                authorDetails.companyId = account.company.id;
                authorDetails.companyName = account.company.companyDescription;
            }
        }
        return authorDetails;
    }

    getCompanyDetails(): CompanyInfo {
        const session = JSON.parse(sessionStorage.getItem('session'));
        const companyDetails = session?.context?.session?.account?.company || null;
        return {
            companyNumber: companyDetails?.id,
            companyDescription: companyDetails?.companyDescription,
            companyAutoAuth: companyDetails?.autoAuthorization,
            companyPropertyAuth: companyDetails?.propertyAuthorization
        };
    }

    getCookie(cookieKey: CookieKey): string {
        const cookies = this.document.cookie.split(';');
        let cookieValue: string | undefined;
        for (const cookie of cookies) {
            const trimCookie = cookie.trim();
            if (trimCookie.startsWith(cookieKey.key)) {
                cookieValue = trimCookie.split('=')[1];
                break;
            }
        }
        return cookieValue;
    }

    getCookieDomain(): string {
        let domain = this.window.location.host;
        if (domain === commonConstants.PAGES.LOCAL_DOMAIN) {
            domain = 'localhost';
        } else {
            domain = 'nationwide.com';
        }
        return domain;
    }

    getEUAToken(): string {
        return sessionStorage.getItem('jwt') || null;
    }

    getSession(): any {
        return JSON.parse(sessionStorage.getItem('session'));
    }

    getTPISession(sessionID: string): Observable<TPISession> {
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.apiCommonService.buildHeader({
            appClientId: this.environment.authorize.client_id,
            authToken: euaToken,
            nwAppId: '',
            contentType: 'application/json',
            headerType: 'X-NW-Message-ID'
        });
        const url = this.environment.apigeeEnvironment + this.environment.tpiSession + sessionID;
        return this.http.get<TPISession>(
            url,
            { headers }
        );
    }

    /**
     * save eua token in sessionstorage
     * @param {string} accessToken : eua token
     * @param {SearchFields} autoSearchFields:
     * @protected
     * @return {void}
     */
    initializeSessionStorage(accessToken: string, autoSearchFields?: SearchFields): void {
        sessionStorage.setItem('jwt', accessToken);
    }

    get isLoggedIn(): boolean {
        return this.loggedIn;
    }
    set isLoggedIn(loggedIn: boolean) {
        this.loggedIn = loggedIn;
        this.checkLoginSub.next(this.loggedIn);
    }

    loginSubject(): BehaviorSubject<boolean> {
        return this.checkLoginSub;
    }

    redirectToChangePassword(): void {
        this.ngZone.run(() => {
            this.route.navigate(['password-change']).then(() => {
                this.logger.info(`Redirect to password-change page`);
            }).catch((error) => {
                this.logger.error(`Redirect to password-change page failed`, { error });
            });
        });
    }

    redirectToLegacy(): void {
        this.route.navigate([LANDING_PAGES.LANDING]);
    }

    redirectToPolicyInquiry(): void {
        this.ngZone.run(() => {
            this.route.navigate(['policy-search']).then(() => {
                this.logger.info(`Redirect to policy inquiry page`);
            }).catch((error) => {
                this.logger.error(`Redirect to policy inquiry page failed`, { error });
            });
        });
    }

    resetEuaToken(): Observable<any> {
        const euaToken = sessionStorage.getItem('jwt');
        const headers = this.apiCommonService.buildHeader({
            appClientId: this.environment.authorize.client_id,
            authToken: euaToken,
            nwAppId: '',
            contentType: 'application/json',
            headerType: 'X-NW-Message-ID'
        });
        const url = `${this.environment.apigeeEnvironment}${this.environment.enterpriseUserAuth}`;
        return this.http.get(url, { headers }).pipe(
            tap(() => {
                this.loggerService.info(`Before EUA token reset - resetEuaToken() : ${sessionStorage.getItem('jwt')}`);
            }),
            switchMap((response: any) => {
                const millisecond = 1000;
                const euaExpire = 1080;
                const expireTimeStamp = Math.floor(Date.now() / millisecond + euaExpire);
                this.initializeSessionStorage(euaToken);
                this.setCookie(CookieKeys.TPIToken, sessionStorage.getItem('jwt'));
                this.setCookie(CookieKeys.TPITokenExp, expireTimeStamp.toString());
                return of(response);
            }),
            tap(() => {
                this.loggerService.info(`After EUA token reset - resetEuaToken() : ${sessionStorage.getItem('jwt')}`);
            })
        );
    }

    resetForgotPasswordTokens(): void {
        this.changedPassword = false;
        this.enteredEmailAddress = false;
        this.confirmedEmailAddress = false;
    }

    routeTo(path: string): void {
        this.ngZone.run(() => {
            this.route.navigate([path]).then(() => {
                this.logger.info(`Redirect to ${path} page`);
            }).catch((error) => {
                this.logger.error(`Redirect to ${path} page failed`, { error });
            });
        });
    }

    setCookie(key: string, value: string): void {
        const domain = this.getCookieDomain();
        this.document.cookie = `${key}=${value};domain=${domain}`;
    }

    setDataFromQueryString(): void {
        const href = this.window.location.href;
        const qParameterStart = href.indexOf('?');
        if (qParameterStart >= 0) {
            const queryString = href.slice(Math.max(0, qParameterStart));
            const urlParameters: URLSearchParams = new URLSearchParams(queryString);
            if (urlParameters.has(CookieKeys.SessionUUID)) {
                this.setCookie(CookieKeys.SessionUUID, urlParameters.get(CookieKeys.SessionUUID));
            }
            if (urlParameters.has(CookieKeys.TPIToken)) {
                this.setCookie(CookieKeys.TPIToken, urlParameters.get(CookieKeys.TPIToken));
            }
            if (urlParameters.has(CookieKeys.TPITokenExp)) {
                this.setCookie(CookieKeys.TPITokenExp, urlParameters.get(CookieKeys.TPITokenExp));
            }
        }
    }

    updateUserLastLogin(userInfo: UserInfoResponse): void {
        const authorDetails: AuthorDetails = {
            userId: userInfo?.user?.id,
            userName: userInfo?.user?.userId,
            role: userInfo?.user?.role?.id,
            companyId: userInfo?.user?.company?.id,
            companyName: userInfo?.user?.company?.companyDescription
        };
        if (!authorDetails.userId ||
            !authorDetails.userName ||
            !authorDetails.role ||
            !authorDetails.companyId) {
            this.loggerService.info(`updateUserLastLogin: Not updating the last login time due to one or more undefined fields`, {
                authorDetails
            });
            return;
        }
        const currentTimeStamp = Date.now();
        const modifyUserRequest: ModifyUserAccountInfo = {
            userId: authorDetails.userName,
            userNumber: authorDetails.userId,
            firstName: '',
            lastName: '',
            email: '',
            role: authorDetails.role,
            companyNumber: authorDetails.companyId,
            lastLogin: currentTimeStamp
        };
        this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: updateUserLastLogin: Try to update the last login time for ${authorDetails.userName} to time ${new Date(currentTimeStamp)}`, {
            request: modifyUserRequest
        });
        this.manageUserAccountsService.updateUserLastLogin(modifyUserRequest).subscribe({
            error: (error: HttpErrorResponse) => {
                this.loggerService.error(`${LOGGER_CONSTANTS.splunkDashboard}: updateUserLastLogin: Error when tried to update last login time for ${authorDetails.userName} `, {
                    error,
                    request: modifyUserRequest
                });
            },
            next: (response: ManageUserAccountsAccountInfo) => {
                this.loggerService.info(`${LOGGER_CONSTANTS.splunkDashboard}: updateUserLastLogin: Successfully updated the last login time to ${response?.lastLogin} for ${authorDetails.userName}`, {
                    request: modifyUserRequest,
                    response
                });
            }
        });
    }
}

export interface CookieKey {
    key: CookieKeys.SessionUUID | CookieKeys.TPIToken | CookieKeys.TPITokenExp;
}
