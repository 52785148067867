<form [formGroup]="bulkEditForm" *ngIf="bulkEditForm && companySearchForm" #formBulkEdit>
    <bolt-notification type="info">
        For security reasons, you will be logged out of your Nationwide online session after 20 minutes. You’ll have the ability to log in again if needed.
    </bolt-notification>
    <h2 class="bolt-heading-md">Mortgage Bulk Update - Personal Lines</h2>
    <bolt-notification *ngIf="errorMessage">{{errorMessage}}</bolt-notification>
    <div class="mortgage-search-section divider-bulk">
        <app-mortgage-search [parentForm]="companySearchForm" [submitOptions]="submitOptionsForCompanySearch" (companySearch)="companySearch()"
            (resetCompanySearch)="resetCompanySearch()"></app-mortgage-search>
        <bolt-waiting-indicator id="selectMortgageField" starthidden>Searching Mortgage Companies...</bolt-waiting-indicator>
        <div class="bolt-row bolt-space-bottom-lg" *ngIf="companyMap?.size > 0">
            <div class="bolt-col-12 section-full">
                <h2 class="bolt-body-copy-md"><strong>Select mortgage</strong></h2>
            </div>
            <div class="bolt-col-12 section-full">
                <bolt-select ngDefaultControl formControlName='bankName' arialabel="Select bank name" required
                    [attr.error]="checkForError('bankName', bulkEditForm, appendOption(submitOptions, {label: 'Mortgage'}))">
                    <option *ngFor="let company of companyMap | keyvalue" [value]="company.key"> {{ company.value.displayText }}</option>
                </bolt-select>
            </div>
        </div>
    </div>

    <ng-container>
        <div>
            <p>Max 30 rows. The bulk upload function replaces the <strong>first mortgage</strong> listed on personal
                insurance policy only.</p>
        </div>
        <div #messageBlock>
            <bolt-notification class="content-block bolt-space-bottom-lg" type="success" *ngIf="successMessage">
                {{successMessage.replace('@@x',successfulPolicies).replace('@@y',totalPolicies)}}
            </bolt-notification>
            <bolt-notification *ngIf="errorMessageBulkEdit" [innerHTML]="errorMessageBulkEdit | sanitizeHtml"></bolt-notification>
        </div>
        <div class="bolt-docs--component-container__content ">
            <p class="docs-md">
                <a href="javascript:void(0);" (click)="downloadSampleBulkXLSX()">Download XLSX Sample</a>
                <bolt-contextual-help>
                    Use this sample spreadsheet to begin organizing your uploads before copying them into the table
                    below.
                </bolt-contextual-help>
            </p>
        </div>
    </ng-container>
    <tpi-bulk-grid [parentForm]='bulkEditForm' [contextual]="" formControlName="grid" ngDefaultControl [(resetGrid)]="resetGrid"></tpi-bulk-grid>
    <app-bulk-button-bar (onSubmit)="submit()" (onReset)="resetAll()"></app-bulk-button-bar>
</form>
<bolt-waiting-indicator id="bulkRedirectWait" fullscreen starthidden></bolt-waiting-indicator>
<bolt-modal id="confirmationToSubmit" type="question" (bolt-modal-primary-button-click)="submitOk()" (bolt-modal-secondary-button-click)="submitCancel()" (bolt-modal-close)="scrollToMessage()"
    heading="Before you submit..." primarybutton="Confirm" secondarybutton="Cancel">
    <p>Reminder: bulk upload limit is <strong>30 policies</strong>. If you entered more than 30, only the first 30
        will be accepted.</p>
    <p>Please confirm that you are updating the <strong>first mortgage</strong> on each policy listed.</p>
</bolt-modal>