<h2>Web Support</h2>
<bolt-notification type="info">
    For security reasons, you will be logged out of your Nationwide online session after 20 minutes. You’ll have the ability to log in again if needed.
</bolt-notification>
<app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>
<div class="margin-y border-bottom">
    <tpi-search-user-form [parentForm]="searchUserForm" label="Search users" (searchEmit)="performSearch($event)"></tpi-search-user-form>
</div>
<div *ngIf="users != null else inputDataPrompt">
    <div *ngIf="users?.length > 0 else noResultPrompt">
        <table mat-table [dataSource]="dataSource" aria-label="Company list table">
            <ng-container matColumnDef="content">
                <th mat-header-cell *matHeaderCellDef aria-hidden="true"> </th>
                <td mat-cell *matCellDef="let row">
                    <tpi-user-panel [manageUserAccountsAccountInfo]="row" (errorEmitter)="setError($event)" (waitIndicatorEmitter)="showWaitIndicator($event)">
                    </tpi-user-panel>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['content']" aria-hidden="true"></tr>
            <tr mat-row *matRowDef="let row; columns: ['content']"></tr>
        </table>
        <mat-paginator class="center" #paginator *ngIf="users.length > 10" [pageSize]="pageSize" [pageSizeOptions]="paginationSettings.pageSizeOptions" aria-label="paginationSettings.ariaLabelValue"
            [showFirstLastButtons]="true" [length]="totalSize" [pageIndex]="currentPage" (page)="pageEvent = handlePage($event)">
        </mat-paginator>
    </div>
    <ng-template #noResultPrompt>
        <tpi-generic-prompt boltIconName="folder" title="No results">
            <ng-container additionalContent>
                <span>Try searching for users by their username or email address</span>
            </ng-container>
        </tpi-generic-prompt>
    </ng-template>
</div>
<ng-template #inputDataPrompt>
    <tpi-generic-prompt [boltIconName]="'person'" [title]="'Search results will appear here'">
        <ng-container additionalContent>
            <span>Search by username or email address</span>
        </ng-container>
    </tpi-generic-prompt>
</ng-template>
<div>
    <bolt-button-bar>
        <bolt-button slot="forward" type="primary" (click)="resetPage()" [disabled]="users == null">Clear results</bolt-button>
    </bolt-button-bar>
</div>
<app-wait-indicator></app-wait-indicator>