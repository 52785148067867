<div class="bolt-space-bottom-xl">
    <bolt-notification type="info">
        For security reasons, you will be logged out of your Nationwide online session after 20 minutes. You’ll have the ability to log in again if needed.
    </bolt-notification>
    <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>

    <app-search-success-panel [showSuccessMessage]="showSuccessMessage" [successMessage]="successMessage"></app-search-success-panel>

    <div class="bolt-row">
        <div class="bolt-col bolt-space-bottom-lg">
            <h2 class="bolt-heading-md">Manage Company List</h2>
        </div>
        <div class="bolt-col bolt-space-bottom-lg add-new-company">
            <tpi-add-new-company (reload)="reload()" (waitIndicator)="showWaitIndicator($event)" (onError)="showResultsError($event)"></tpi-add-new-company>
        </div>
    </div>

    <tpi-filter-component [options]="filterOptions" listCount="0" [dataToFilter]="dataToFilter" (filterByNameOrEmail)="filterByNameOrEmail($event)" class="bolt-space-bottom-lg"></tpi-filter-component>

    <div *ngIf="dataSource.data?.length == 0" class="no-company-container">
        <div class="bolt-row">
            <div class="bolt-col align-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="120" height="120">
                    <defs />
                    <path class="pale-gray"
                        d="M12.5,7.5V2.0293l-7,6.7583V22h13V7.5ZM11.5,21h-5V9.2124l5-4.8276V21Zm6,0h-5V8.5h5Zm-3-9.5h-1v-1h1Zm2,0h-1v-1h1Zm-2,2h-1v-1h1Zm2,0h-1v-1h1Zm-2,2h-1v-1h1Zm2,0h-1v-1h1Zm-2,2h-1v-1h1Zm2,0h-1v-1h1Zm-9-7h1v1h-1Zm2,0h1v1h-1Zm-2,2h1v1h-1Zm2,0h1v1h-1Zm-2,2h1v1h-1Zm2,0h1v1h-1Zm-2,2h1v1h-1Zm2,0h1v1h-1Z" />
                </svg>
            </div>
        </div>
        <div class="bolt-row bolt-space-bottom-md">
            <div class="bolt-col-12 align-center">
                <h2>No results</h2>
            </div>
            <div class="bolt-col align-center">
                <tpi-add-new-company (reload)="reload()" (waitIndicator)="showWaitIndicator($event)" (onError)="showResultsError($event)"></tpi-add-new-company>
            </div>
        </div>
    </div>
    <div class="company-list" *ngIf="dataSource.data?.length > 0">
        <table mat-table [dataSource]="dataSource" class="bolt-space-bottom-md" aria-label="Company list table">
            <ng-container matColumnDef="companyDescription">
                <th mat-header-cell *matHeaderCellDef> NAME </th>
                <td mat-cell *matCellDef="let element"><a href="javascript:void(0);" (click)="navigateToUserList(element)">{{element.companyDescription}}</a></td>
            </ng-container>
            <ng-container matColumnDef="companyPropertyAuth">
                <th mat-header-cell *matHeaderCellDef> PROPERTY </th>
                <td mat-cell *matCellDef="let element">
                    <bolt-icon name="checkmark-circle-filled" color="dark-mint" title="Property auth enabled" tabindex="0" *ngIf="element.propertyAuthorization == 1"></bolt-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="companyAutoAuth">
                <th mat-header-cell *matHeaderCellDef> AUTO </th>
                <td mat-cell *matCellDef="let element">
                    <bolt-icon name="checkmark-circle-filled" color="dark-mint" title="Auto auth enabled" tabindex="0" *ngIf="element.autoAuthorization == 1"></bolt-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef aria-hidden="true"> </th>
                <td mat-cell *matCellDef="let element"> <a href="javascript:void(0);" (click)="onModifyCompanyClick($event, element)">
                        Edit
                    </a></td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef aria-hidden="true"> </th>
                <td mat-cell *matCellDef="let element"><a href="javascript:void(0);" (click)="onDeleteCompanyClick(element)">
                        Delete
                    </a> </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dataTableSettings.columnDefinition"></tr>
            <tr mat-row *matRowDef="let row; columns: dataTableSettings.columnDefinition;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="dataTableSettings.paginationSettings.pageSizeOptions" *ngIf="dataSource?.data.length > 10" aria-label="dataTableSettings.paginationSettings.ariaLabelValue"
            (page)="pageEvent = handlePage($event)">
        </mat-paginator>
    </div>
</div>
<tpi-delete-company (reload)="reload()" [deleteCompanyInfo]="deleteCompanyInfo" (onError)="showResultsError($event)"></tpi-delete-company>
<tpi-modify-company (reload)="reload()" (waitIndicator)="showWaitIndicator($event)" (onError)="showResultsError($event)"></tpi-modify-company>
<bolt-waiting-indicator id="manageCompanyWaitingIndicator" fullscreen starthidden></bolt-waiting-indicator>