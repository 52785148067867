<form [formGroup]="filterTableForm" *ngIf="filterTableForm">
    <bolt-notification type="info">
        For security reasons, you will be logged out of your Nationwide online session after 20 minutes. You’ll have the ability to log in again if needed.
    </bolt-notification>
    <app-search-success-panel [showSuccessMessage]="showSuccessMessage" [successMessage]="successMessage"></app-search-success-panel>

    <div *ngIf="showSuccessMessage" class="bolt-space-bottom-lg">
    </div>

    <bolt-notification *ngIf="hasError" [innerHTML]="errorMessage | sanitizeHtml"></bolt-notification>

    <div class="bolt-row bolt-space-bottom-lg bolt-align-items-center">
        <div class="bolt-col-sm-8">
            <h2 class="bolt-heading-md">Manage User Accounts</h2>
        </div>
        <div class="bolt-col-sm-4 align-right">
            <tpi-add-new-user [parentForm]="userForm" [submitOptions]="submitOptionsUser" [roleOptions]="roleOptions" [company]="company" (errorMessageEmit)="showResultsError($event)"
                (resetFormEmit)="resetUserForm($event)" (resetResultMessagesEmit)="resetResultMessages($event)" (retrieveUsersByCompanyEmit)="retrieveUsersByCompany($event)"></tpi-add-new-user>
        </div>
    </div>

    <hr>

    <div class="bolt-row bolt-align-items-center">
        <div class="bolt-col">
            <h2 *ngIf="company?.companyDescription">{{company.companyDescription}}</h2>
        </div>
        <div class="bolt-col align-right">
            <a href="javascript:void(0);" (click)="openUpdateCompanyHelpModal()">How do I update my company info?</a>
        </div>
    </div>

    <hr class="border-thin">

    <div class="bolt-row bolt-space-bottom-lg  bolt-justify-content-between">
        <div class="bolt-col-md-8">
            <tpi-filter-by-name-or-email (filterEmit)="filterByNameOrEmail($event)" [label]="'Filter by name or email'" [parentForm]="filterTableForm"></tpi-filter-by-name-or-email>
            <p><strong>{{totalUsers}}</strong> total users</p>
        </div>

        <div class="bolt-col-md-4 .bolt-offset-md-4">
            <tpi-filter-by-role (filterEmit)="filterByRole($event)" [label]="'Filter by role'" [roleUserCounts]="roleUserCounts" [parentForm]="filterTableForm"></tpi-filter-by-role>
        </div>
    </div>

    <div class="bolt-row bolt-space-bottom-2xl bolt-justify-content-between">
        <div class="bolt-col-8">
            <div class="bolt-row">
                <div class="bolt-col-auto">
                    <tpi-edit-user [selectedTableEntries]="selectedTableEntries" [parentForm]="userForm" [submitOptions]="submitOptionsUser" [roleOptions]="roleOptions"
                        [companyNumber]="company.companyNumber" (errorMessageEmit)="showResultsError($event)" (resetFormEmit)="resetUserForm($event)"
                        (resetResultMessagesEmit)="resetResultMessages($event)" (retrieveUsersByCompanyEmit)="retrieveUsersByCompany($event)"
                        (isLoadingEmit)="showWaitIndicator($event)"></tpi-edit-user>
                </div>
                <div class="bolt-col-auto">
                    <bolt-button slot="cancel" type="secondary" class="buttons" [disabled]="selectedTableEntries.length < 1" (click)="openModifyUserModal(modifyUserActionUnlock)">Unlock
                        user</bolt-button>
                </div>
                <div class="bolt-col-auto">
                    <bolt-button slot="cancel" type="secondary" class="buttons" [disabled]="selectedTableEntries.length < 1" (click)="openModifyUserModal(modifyUserActionReset)">Reset
                        password</bolt-button>
                </div>
            </div>
        </div>
        <div class="bolt-col-3 .bolt-offset-md-1 align-right">
            <bolt-button class="buttons" type="primary" [disabled]="selectedTableEntries.length < 1" (click)="openModifyUserModal(modifyUserActionDelete)">Delete user</bolt-button>
        </div>

        <tpi-delete-reset-unlock-user (errorMessageEmit)="showResultsError($event)" (successMessageEmit)="showResultsSuccess($event)" (resetResultMessagesEmit)="resetResultMessages($event)"
            (resetTableSelectionEmit)="resetTableSelection($event)" (retrieveUsersByCompanyEmit)="retrieveUsersByCompany($event)" [selectedTableEntries]="selectedTableEntries"
            [action]="modifyUserAction" [modifyUserInput]="modifyUserInput" [company]="company">
        </tpi-delete-reset-unlock-user>
    </div>

    <div *ngIf="tableEntries?.length > 0 && filteredTableEntries?.length > 0">
        <tpi-manage-user-accounts-table [tableEntries]="filteredTableEntries" [(selectedTableEntries)]="selectedTableEntries"></tpi-manage-user-accounts-table>
    </div>
    <div *ngIf="tableEntries?.length == 0  || filteredTableEntries?.length == 0" class="bolt-space-bottom-2xl">
        <hr class="bolt-space-bottom-2xl">
        <div class="bolt-row">
            <div class="bolt-col align-center">
                <bolt-icon class="size-100 pale-gray" name="person" title="person icon"></bolt-icon>
            </div>
        </div>
        <div class="bolt-row bolt-space-bottom-md">
            <div class="bolt-col align-center">
                <h2 *ngIf="tableEntries.length == 0">No users for this company yet</h2>
                <h2 *ngIf="filteredTableEntries?.length == 0 && tableEntries.length != 0" class="no-results-found">No results found</h2>
                <span *ngIf="filteredTableEntries?.length == 0 && tableEntries.length != 0">Try searching for users by
                    their
                    username or email address</span>

            </div>
        </div>
        <div class="bolt-row">
            <div class="bolt-col align-center">
                <tpi-add-new-user [parentForm]="userForm" [submitOptions]="submitOptionsUser" [roleOptions]="roleOptions" [company]="company" (errorMessageEmit)="showResultsError($event)"
                    (resetFormEmit)="resetUserForm($event)" (resetResultMessagesEmit)="resetResultMessages($event)" (retrieveUsersByCompanyEmit)="retrieveUsersByCompany($event)"></tpi-add-new-user>
            </div>
        </div>
    </div>
</form>

<tpi-notification-modal>
    <ng-container modalWarning>
        <bolt-modal #updateCompanyHelpModal primarybutton="Close" id="updateCompanyHelpModal">
            Your company information can be found when editing mortgage loans. If updates
            are needed, please email <a href="mailto: tpgate@nationwide.com">tpgate@nationwide.com</a> with the
            following information:
            <ul class="margin-top-bottom-sm">
                <li>Company Name</li>
                <li>Legal information</li>
                <li>Address, all that apply</li>
                <li>Loan number</li>
                <li>Policy number</li>
            </ul>
            We will contact you within 5 business days to confirm the change.
            <br>
            <strong>Do not send policy change requests to this mailbox.</strong>
        </bolt-modal>
    </ng-container>
</tpi-notification-modal>
<bolt-waiting-indicator id="userManagementWaitIndicator" fullscreen starthidden></bolt-waiting-indicator>