import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractTimeoutComponent } from '@nationwide/internet-servicing-angular-components';
import {
    from,
    fromEvent,
    merge,
    Observable, Subject,
    Subscription,
    timer
} from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class TpiIdleService extends AbstractTimeoutComponent {
    private activityEvents$: Observable<any>;
    private idleEnd$: Subject<boolean> = new Subject();
    private idleSubscription: Subscription = new Subscription();
    private idleTimeout$: Subject<boolean> = new Subject();
    private idleTimeoutWarning$: Subject<boolean> = new Subject();
    private idleWarningTimerSubscription: Subscription = new Subscription();
    private isAuth = true;
    private millisec: number;
    private minutes: number;
    private seconds: number;
    private timeoutTime: number;
    constructor(router: Router) {
        super(router);
        this.minutes = environment.idleTimeoutInMinutes;
        this.seconds = 60;
        this.millisec = 1000;
        this.timeoutTime = this.minutes * this.seconds * this.millisec;
    }
    checkTimeoutWarning(): Subscription {
        return timer(0, this.millisec).subscribe(() => {
            if (this.isOpen) {
                this.idleTimeoutWarning$.next(true);
                this.idleSubscription.unsubscribe();
                this.idleWarningTimerSubscription.unsubscribe();
                this.isOpen = false;
            }
        });
    }
    endUserSession(voluntary: boolean): void {
        if (!voluntary) {
            this.idleTimeout$.next(true);
        }
        this.idleWarningTimerSubscription.unsubscribe();
        this.idleEnd$.unsubscribe();
        this.idleTimeout$.unsubscribe();
        this.idleTimeoutWarning$.unsubscribe();
        this.idleSubscription.unsubscribe();
        this.isAuth = false;
    }
    getAuth(): any {
        return this.isAuth;
    }
    getIdleEnd(): Subject<boolean> {
        return this.idleEnd$;
    }
    getIdleTimeout(): Subject<boolean> {
        return this.idleTimeout$;
    }
    getIdleTimeoutWarning(): Subject<boolean> {
        return this.idleTimeoutWarning$;
    }
    onIdleEnd(): Observable<boolean> {
        return this.idleEnd$;
    }
    onTimeout(): Observable<boolean> {
        return this.idleTimeout$;
    }
    onTimeoutWarning(): Observable<boolean> {
        return this.idleTimeoutWarning$;
    }
    refreshUserSession(): void {
        this.startWatching();
    }
    setActivityEvents(events$: Observable<any>): void {
        this.activityEvents$ = events$;
    }
    setAuth(isAuth: boolean): any {
        this.isAuth = isAuth;
    }

    setIdleSubscription(idleSub: any): void {
        this.idleSubscription = idleSub;
    }
    setIdleWarningSubscription(idleWarnSub: any): void {
        this.idleTimeoutWarning$ = idleWarnSub;
    }
    setIdleWarningTimerSubscription(idleWarningSub: any): any {
        this.idleWarningTimerSubscription = idleWarningSub;
    }
    startWatching(): any {
        if (!this.activityEvents$) {
            this.activityEvents$ = merge(
                fromEvent(window, 'mousemove'),
                fromEvent(window, 'mousedown'),
                fromEvent(window, 'resize'),
                fromEvent(window, 'scroll'),
                fromEvent(document, 'touchstart'),
                fromEvent(document, 'touchend'),
                fromEvent(document, 'keydown')
            );
        }
        this.activityEvents$ = from(this.activityEvents$);
        if (this.idleWarningTimerSubscription) {
            this.idleWarningTimerSubscription.unsubscribe();
        }
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
        if (this.idleWarningTimerSubscription) {
            this.idleWarningTimerSubscription.unsubscribe();
        }
        this.timeoutTime = Date.now() + this.minutes * this.seconds * this.millisec;
        this.init();
        this.isOpen = false;
        this.idleWarningTimerSubscription = this.checkTimeoutWarning();
    }
    tokenExpiration(): number {
        let expire;
        if (this.getAuth()) {
            expire = this.timeoutTime;
        }
        return expire;
    }
}
